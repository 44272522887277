.carousel-container {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 1px 2px 12px #8897aa;
  animation: changeShadowColorPrimary 4s linear infinite alternate; 
}

@keyframes changeShadowColor {
  0% { box-shadow: 1px 2px 12px #f0f3fa; }
  25% { box-shadow: 1px 2px 12px #d5deef; } 
  50% { box-shadow: 1px 2px 12px #b1c9ef; }
  75% { box-shadow: 1px 2px 12px #8aaee0; } 
  100% { box-shadow: 1px 2px 12px #638ecb; }
}

 @keyframes changeShadowColorPrimary {
  0% { box-shadow: 1px 2px 12px #8897aa; }
  25% { box-shadow: 1px 2px 12px #d35d5d; } 
  50% { box-shadow: 1px 2px 12px #92c092; }
  75% { box-shadow: 1px 2px 12px #7878c7; } 
  100% { box-shadow: 1px 2px 12px #b8b77b; }
}

.carousel-image {
  width: 100%;
  @media screen and (min-width: 350px) {
      height: 250px; 
  }
  
  @media screen and (min-width: 768px) {
      height: 450px; 
    }
   
  @media screen and (min-width: 1100px) {
      height: 600px; 
  }
}