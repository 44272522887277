  .accordion .accordion-item {
      background-color: #282c34;
  }

  .accordion .accordion-body {
      color: aliceblue;
      text-align: justify;
  }

  .accordion{
    margin-bottom: 40px;
  }

  .url-whatsapp{
    color: aliceblue;
  }

