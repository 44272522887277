.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.App-logo {
  width: 110px;
  height: 90px;
  margin-left: 50px;
  animation: spin 5s linear infinite;
}

.welcome-text {
  color: #b3b2b2;
  font-size: 40px;
  margin-top: 30px;
}

.dots {
  animation: blink 2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}