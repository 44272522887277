.responsive-logo {
  margin-right: 20px;
  @media screen and (min-width: 320px) {
    width: 80px;
    height: 60px;
  }

  @media screen and (min-width: 768px) {
    width: 90px;
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    width: 120px;
    height: 90px;
    margin-right: 50px;
  }
}

.nav-link .icono {
  @media screen and (min-width: 320px) {
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: 768px) {
    width: 35px;
    height: 35px;
  }

  @media screen and (min-width: 1024px) {
    width: 45px;
    height: 45px;
  }
}

.boton-contacto:hover {
  background-color:rgb(200, 201, 203);
  border-color:rgb(200, 201, 203);
  color: #282c34;
}

.boton-contacto {
  background-color: #282c34;
  color: rgb(156, 158, 161);
  font-family: "Nunito Sans";
  font-optical-sizing: auto;
  font-weight: 700;
  border-radius: 20px;
  width: 140px;
  overflow: hidden;
  border-color:rgb(153, 153, 153);

  @media screen and (min-width: 320px) {
    font-size: 16px;
  }

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
}