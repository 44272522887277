.footer-text-content span {
    margin-left: 5px;
} 

.imagen-logo-rubio {
    width: 200px;
    height: 90px;
    margin-top: -20px;
}

.footer-container{
    border-top: 2px solid;
    border-color: #4b525e;
  justify-content: space-evenly;
}

.image-container {
    display: flex;
    justify-content: space-evenly;
}

.footer-text,
.footer-text-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: aliceblue;
    font-size: 14px;
    margin-bottom: 5px;
}