::-webkit-scrollbar {
  display: none;
}

.video-container {
  display: flex;
  overflow-x: auto;
}

.video-margen {
  margin: 30px;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 15px;
}

.title-video, .title-video2{
  color: aliceblue;
  text-align: center;
}

.video-margen {
  position: relative;
  text-align: center; /* Alinea el contenido al centro */
}

.boton-contacto {
  display: inline-block; /* Permite que el botón se comporte como un bloque en línea */
  padding: 5px 20px;
  background-color: #282c34;
  color: rgb(156, 158, 161);
  font-family: "Nunito Sans";
  font-size: 14px; /* Tamaño de fuente más pequeño */
  font-weight: 700;
  border: 2px solid rgb(153, 153, 153); /* Borde sólido */
  border-radius: 20px;
  width: auto; /* Ancho automático según el contenido */
  cursor: pointer;
  margin-bottom: 10px;
}

.boton-contacto:hover {
  background-color: #1e2127; /* Color de fondo al pasar el cursor */
}

.share-modal{
  border-radius: 10px;
  color: aliceblue;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(153, 153, 153);
}

.share-modal button {
  padding: 5px 10px;
  background-color: transparent; 
  color: aliceblue;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

