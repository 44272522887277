body {
  background-color: #282c34;
  width: auto;
}

.centrado-margen2 {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;

  @media screen and (min-width: 320px) {
    width: 95%;
  }

  @media screen and (min-width: 768px) {
    width: 95%;
  }

  @media screen and (min-width: 1024px) {
    width: 80%;
  }
}